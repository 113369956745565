import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import classes from "./NavigationBar.module.css";
import Typography from "@mui/material/Typography";

// USe MUI instead of bootstrap

function NavigationBar() {
  return (
    <React.Fragment>
      <AppBar className={classes.AppBarStyles}>
        <Toolbar className={classes.ToolBarStyles}>
          <Link to={"/"}>Home</Link>
          <Link to={"/how-it-works"} className={classes.test}>How It Works</Link>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}

export default NavigationBar;
