import classes from './PurchaseConfirmationItem.module.css';
import Button from "@mui/material/Button";
import { useState } from 'react';
import { Alert } from '@mui/material';

import db from "../components/firebase";
import { doc, collection, setDoc } from "firebase/firestore";

function PurchaseConfirmationItem(props) {

    const orderedShipId = window.localStorage.getItem('dataForPurchaseConfirmation');

    const [data, setData] = useState('')
    const [missingDataWarning, setMissingDataWarning] = useState(false)
    const [confirmPurchase, setConfirmPurchase] = useState(false)

    async function confirmHandler() {
        if(data === '' || data.length < 5) {
            console.log("Is empty");
            setMissingDataWarning(true);
            return 0
        } else {
            const newDodRef = doc(collection(db, 'PlacedOrders'));  
            const shipAndUserData = {
                shipName: orderedShipId,
                userName: data
            }
            await setDoc(newDodRef, shipAndUserData);
            setMissingDataWarning(false)
            setConfirmPurchase(true)
        }

    }

    return (<div className={classes.contentDivss}>
        <div className={classes.content}><h2>Purchase Confimation Form</h2></div>
        <h3 className={classes.content}>{orderedShipId}</h3>
        <label htmlFor={'inGameName'} className={classes.content}>Your in game name</label>
        <input className={classes.inputText} type={'text'} id={'inGameName'} onChange={(e) => setData(e.target.value)}></input>
        <Button className={classes.confirmButton} variant="contained" color="success" onClick={confirmHandler}>Confirm</Button>

        {missingDataWarning && <Alert className={classes.muiAlert} severity="error">Missing your in game name!</Alert>}
        {confirmPurchase && <Alert severity="success">Your Order has been placed</Alert>}
    </div>)

}

export default PurchaseConfirmationItem