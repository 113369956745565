import { Route, Routes, BrowserRouter, Router} from "react-router-dom";

import OverviewPage from "./pages/OverviewPage";
import HowItWorks from "./pages/HowItWorks";
import NavigationBar from "./components/layout/NavigationBar";
import PurchaseConfirmation from "./pages/PurchaseConfirmation";

function App() {
  return (
    <div className="App">
      <NavigationBar />

        <Routes>
          <Route path="/how-it-works" exact element={<HowItWorks />}></Route>
          <Route path="/" exact element={<OverviewPage />}></Route>
          <Route path="/confirmation" element={<PurchaseConfirmation />}></Route>
        </Routes>
      
    </div>
  );
}

export default App;
