import classes from "./ListOfItems.module.css";
import ItemForSale from "./ItemForSale";
import ShipModal from './ShipModal';
import { useState } from 'react'



function ListOfItems(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalData, setModalData] = useState([])

  function openModalForPurchase (props) {
    setModalIsOpen(true)
    console.log(`MODAL PROPS: ${props['name']}`);
    setModalData([props])
  }

  function closeModalHandler() {
    setModalIsOpen(false);
  }

  function getDataHandler(props) {

  }

  return (
    <div className={classes.content}>
      {props.items.map((item) => (
        <ItemForSale
          name={item["name"]}
          fitting={item["fitting"]}
          price={item["price"]}
          uniqueID={item["uniqueID"]}
          inStorage={item['inStorage']}
          modal={() => openModalForPurchase(item)}
        ></ItemForSale>
        
      ))}

      {modalIsOpen && <ShipModal closeModal={closeModalHandler} shipData={[...modalData]}/>}


    </div>
  );
}

export default ListOfItems;

