import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import classes from './ShipModal.module.css'
import { Link } from "react-router-dom";

function ShipModal(props) {
    
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#291F1E",
    border: "2px solid #FF9F1C",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  function test() {
      window.localStorage.setItem('dataForPurchaseConfirmation', props.shipData[0]['uniqueId']);
  }

  return (
    <Modal open className={classes.modalBackground}>
      <Box sx={style}>
        <Typography onClick={props.closeModal} id="modal-modal-title" variant="h6" component={'span'} align="center" className={classes.exitModal}>
            ×
        </Typography>

        <Typography component={'div'} align="center" className={classes.topPadding}>
            <img src={require(`../resources/${props.shipData[0]['name']}.jpg`)} className={classes.imgForModal} alt={props.shipData[0]['name']}></img>
        </Typography>
    
        <Typography id="modal-modal-description" component={'div'} className={classes.moduleRow}>
            <p className={classes.moduleP}>[{props.shipData[0]['name']}, {props.shipData[0]['uniqueId']}]</p>
        </Typography>

        <Typography component={'div'} className={classes.moduleRow}>
            {props.shipData[0]['fitting']['low'].map((module)=> {
                return(<p className={classes.moduleP}>{module}</p>)
            })}
        </Typography>

        <Typography component={'div'} className={classes.moduleRow}>
            {props.shipData[0]['fitting']['mid'].map((module)=> {
                return(<p className={classes.moduleP}>{module}</p>)
            })}
        </Typography>

        <Typography id="modal-modal-description" component={'div'} className={classes.moduleRow} >
            {props.shipData[0]['fitting']['high'].map((module)=> {
                return(<p className={classes.moduleP}>{module}</p>)
            })}
        </Typography>

        <Typography id="modal-modal-description" component={'div'} className={classes.moduleRow} >
            {props.shipData[0]['fitting']['rigs'].map((module)=> {
                return(<p className={classes.moduleP}>{module}</p>)
            })}
        </Typography>

        <Typography component={'div'} align="left" className={classes.moduleRow}>
            <Button variant="contained" color="success" onClick={test}><Link to={{pathname: './confirmation', data: props.shipData[0]['uniqueId']}} className={classes.purchaseLink}>Purchase</Link></Button>
            <span className={classes.priceTag}>{props.shipData[0]['price']} ISK</span>
        </Typography>

      </Box>
    </Modal>
  );
}

export default ShipModal;
