import { useEffect, useState } from "react";

import db from "../components/firebase";
import { doc, getDocs, collection } from "firebase/firestore";
import ListOfItems from "../components/ListOfItems";

function OverviewPage() {
  const [loadedShips, setLoadedShips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [listOfShipNames, setListOfShipNames] = useState([]);
  // useEffect to only make it fetch once
  useEffect(() => {
    setIsLoading(true);
    const querySnapshot = getDocs(collection(db, "AvailableShips"))
      .then((snapShot) => {
        snapShot.forEach((doc) => {
          const data = doc.data();

          const ship = {
            name: doc.id,
            price: data["Price"],
            inStorage: data.inStorage,
            uniqueId: data["uniqueID"],
            fitting: {
              high: [...data["Fitting"]["high"]],
              mid: [...data["Fitting"]["mid"]],
              low: [...data["Fitting"]["low"]],
              rigs: [...data["Fitting"]["rigs"]],
            },
          };
          setListOfShipNames(listOfShipNames => [...listOfShipNames, doc.id]);
          /* setLoadedShips(loadedShips.push(ship)); */
          setLoadedShips(loadedShips => [...loadedShips, ship]);
          
        });
        
        setIsLoading(false); 
       
      })
      .catch((e) => {
        console.log("ERROR:", e);
      });
  }, []);

  if (isLoading) {
    return <section>IS LOADING..</section>;
  }

  console.log(loadedShips);

  return (
    <div>
      <ListOfItems items={loadedShips} names={listOfShipNames} />
    </div>
  );
}

export default OverviewPage;
