import PurchaseConfirmationItem from "../components/PurchaseConfirmationItem";

function PurchaseConfirmation(props) {
  const data = window.localStorage.getItem("dataForPurchaseConfirmation");
  console.log(data);
  return(
  <div>
      <PurchaseConfirmationItem />
  </div>);
}

export default PurchaseConfirmation;
