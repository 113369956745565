import classes from "./ItemForSale.module.css";


function ItemForSale(props) {
  const formatedData = {
    name: props.name,
    price: props.price,
    uniqueID: props.uniqueID,
    inStorage: props.inStorage,
    fitting: props.fitting,
  };

  function displayBuyScreen() {
      console.log(formatedData);
      props.modal();
  }

  return (
    <div onClick={() => displayBuyScreen(props, formatedData)} className={classes.entry}>
      <img
        src={require(`../resources/${props.name}.jpg`)}
        alt={props.name}
      ></img>
      <p className={classes.entryP}>{props.name}</p>
    </div>
  );
}

export default ItemForSale;
